import { User } from '~/app/lib/songwhipApi/users/lib/mapUser';
import isBot from '~/app/lib/utils/isBot';
import { DeviceTypes } from '~/types';
import { OwnedByAccountsMapped } from '../../mapper/types';

import { StatusTypes, State } from '../types';
import { Features } from './types';

export const selectUserCountry = (state: State) => state.session.country;
export const selectUserLanguage = (state: State) => state.session.language;
export const selectUser = (state: State) => state.session.user.value;
export const selectUserIsBot = (state: State) => isBot(state.session.userAgent);

const selectUserProp = <K extends keyof User>(state: State, prop: K) => {
  const user = selectUser(state);
  return user && user[prop];
};

export const selectUserName = (state: State) => selectUserProp(state, 'name');
export const selectSessionUserId = (state: State) => state.session.userId;

export const selectUserIsLoggedIn = (state: State) =>
  !!selectSessionUserId(state);

export const selectSessionScope = (state: State) => state.session.scope;

export const selectSessionCustomDomain = (state: State) =>
  state.session.customDomain;

export const selectAccountIdSessionScope = (state: State) => {
  const scope = selectSessionScope(state);
  const accountIdScope = scope?.match(/^account:(\d+)/)?.[1];
  if (accountIdScope) return Number(accountIdScope);
};

export const selectPathSessionScope = (state: State) => {
  const scope = selectSessionScope(state);
  return scope?.match(/^path:(.+)/)?.[1];
};

export const selectUserStatus = ({ session }: State) => {
  return session.user.status;
};

export const selectUserIsLoading = (state: State) => {
  const status = selectUserStatus(state);
  const userIsBeingFetched = status === StatusTypes.PENDING;

  // Before the user is fetched and a `status` is set some views may want to
  // show a loading state. If a userId is set (ie. device has userId cookie)
  // but no status is defined yet we assume the user is loading.
  const userWillBeFetched = !!state.session.userId && status === undefined;

  return userIsBeingFetched || userWillBeFetched;
};

/**
 * We have a User object. This is useful to know
 * when deciding whether to show UI. If the User is
 * loaded be can make a decision about whether to
 * show/hide content.
 *
 * We can't always use selectUserIsLoading() as the request
 * may not have been triggered yet (via useEffect()).
 */
export const selectUserIsLoaded = (state: State) =>
  state.session.user.value?.id;

export const selectUserError = (state: State) => {
  if (state.session.user.status !== StatusTypes.REJECTED) return;

  return state.session.user.error;
};

export const selectIsOrchardUser = (state: State) =>
  !!selectUser(state)?.orchardId;

/**
 * Determine whether the active session user can edit an Artist/Album/Track.
 *
 * TODO: Change this to return the the access permission so that the UI can
 * react differently to users who only have 'read' access. For example we'll
 * want to grey-out/disable the "Edit page" action and prompt them to reach
 * out to support if they believe they should have 'write' access.
 */
export const selectUserCanEditItem = (
  state: State,
  {
    ownedByAccounts,
  }: { ownedByAccounts?: OwnedByAccountsMapped } | undefined = {}
) => {
  // we cannot know this during ssr as we have no session context
  if (!process.browser) return undefined;

  // if there is no `userId` cookie then they will never be able to edit
  if (!selectUserIsLoggedIn(state)) return false;

  // we can't yet make a decision as User object is not in store
  if (!selectUserIsLoaded(state)) return undefined;

  const user = selectUser(state);

  // songwhip admins can edit anything, even Items that don't have owners
  if (user?.isAdmin) return true;

  if (!ownedByAccounts) {
    return false;
  }

  const userAccountIds = user?.accounts?.map(({ id }) => id);
  if (!userAccountIds) return false;

  return ownedByAccounts.some(({ id, subscriptionEndsAtTimestamp }) => {
    const hasAccount = userAccountIds.indexOf(id) > -1;
    if (!hasAccount) return;

    const subscriptionActive =
      subscriptionEndsAtTimestamp && subscriptionEndsAtTimestamp > Date.now();

    // user is able to edit if they are a member of an owner
    // account and the account subscription is 'active'
    return subscriptionActive;
  });
};

export const selectUserType = (state: State) => state.session.userType;

export const selectUserBrand = (state: State) => state.session.userBrand;

export const selectIsEnabled = (state: State, feature: Features) =>
  !!state.session.user.value?.featureFlags[feature] ||
  !!state.session.localFeatureFlags?.includes(feature);

export const selectIsSmallDevice = (state: State) =>
  state.session.deviceType && state.session.deviceType === DeviceTypes.SMALL;

export const selectUserGeolocation = (state: State) =>
  state.session.geolocation;

export const selectSessionUtmParams = (state: State) => ({
  utmSource: state.session.utmSource,
  utmMedium: state.session.utmMedium,
});
