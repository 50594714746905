import React, { FC } from 'react';

import Text, { TextProps } from '../Text';

import ApiError from '~/app/lib/errors/ApiError';
import prettyWrap from '~/app/lib/utils/prettyWrap';

type ToTextParams = {
  error: Error;
  status?: number;
};

export interface ErrorTextProps extends Omit<TextProps, 'children'> {
  error?: Error | ApiError;
  toText?: (params: ToTextParams) => string | undefined;
}

const ErrorText: FC<ErrorTextProps> = ({
  error,
  toText: toTextCustom,

  ...props
}) => {
  if (!error) return null;

  const toTextParams = resolveToErrorTextParams(error);
  const customText = toTextCustom && toTextCustom(toTextParams);

  return (
    <Text isParagraph {...props}>
      {prettyWrap(customText || toDefaultErrorText(toTextParams))}
    </Text>
  );
};

export const resolveToErrorTextParams = (
  error: Error | ApiError
): ToTextParams => {
  if (error instanceof ApiError) {
    return {
      error,
      status: error.status,
    };
  }

  return {
    error,
  };
};

export const toDefaultErrorText = ({ error, status }: ToTextParams) => {
  if (status) {
    switch (status) {
      case 0:
        return 'Unable to fetch data, you may be offline';
      case 404:
        return "We couldn't find that";
      case 401:
      case 403:
        return 'Access denied';
      case 500:
        return 'The server encountered a problem';
      default:
        return error.message || `${status} error`;
    }
  }

  return error.message;
};

export default ErrorText;
