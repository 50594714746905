import { OrchardBrands } from './app/lib/songwhipApi/types';

export { ItemTypes } from './app/lib/songwhipApi/types';

export type TypeArtist = 'artist';

export type Link = {
  link: string;
  countries: string[] | null;
};

export type Links = {
  [ServiceType: string]: Link[] | null;
};

export enum DeviceTypes {
  SMALL = 'small',
  LARGE = 'large',
}

/**
 * Note that these values must correspond exactly with the LinkTypes exposed by songwhip-lookup
 */
export enum ServiceTypes {
  AMAZON = 'amazon',
  AMAZON_MUSIC = 'amazonMusic',
  ANGHAMI = 'anghami',
  AUDIUS = 'audius',
  AWA = 'awa',
  AUDIOMACK = 'audiomack',
  BAJAO = 'bajao',
  BANDCAMP = 'bandcamp',
  BANDS_IN_TOWN = 'bandsInTown',
  BEATPORT = 'beatport',
  BEATSTARS = 'beatstars',
  BOOMPLAY = 'boomplay',
  CASH_APP = 'cashApp',
  BLEEP = 'bleep',
  DEEZER = 'deezer',
  DUBSMASH = 'dubsmash',
  DISCORD = 'discord',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  INSTAGRAM = 'instagram',
  ITUNES = 'itunes',
  ITUNES_STORE = 'itunesStore',
  GAANA = 'gaana',
  GOOGLE_PLAY = 'googleplay',
  JIO_SAAVN = 'jioSaavn',
  KKBOX = 'kkbox',
  I_HEART_RADIO = 'iHeartRadio',
  LAST_FM = 'lastFm',
  LINE_MUSIC = 'lineMusic',
  LINKED_IN = 'linkedIn',
  JOOX = 'joox',
  MAILCHIMP = 'mailchimp',
  MUSIXMATCH = 'musixMatch',
  MIXCLOUD = 'mixcloud',
  NAPSTER = 'napster',
  MORA = 'mora',
  OTOTOY = 'ototoy',
  PANDORA = 'pandora',
  PAYPAL = 'paypal',
  QOBUZ = 'qobuz',
  RAKUTEN = 'rakuten',
  RECOCHOKU = 'recochoku',
  RESSO = 'resso',
  REVERB_NATION = 'reverbNation',
  PATREON = 'patreon',
  SEVEN_DIGITAL = 'sevenDigital',
  SONGWHIP = 'songwhip',
  SOUND_CLOUD = 'soundcloud',
  SNAPCHAT = 'snapchat',
  SPOTIFY = 'spotify',
  TELEGRAM = 'telegram',
  TIDAL = 'tidal',
  TIKTOK = 'tiktok',
  TRAXSOURCE = 'traxsource',
  TRILLER = 'triller',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  VK = 'VK',
  YANDEX = 'yandex',
  WYNK = 'wynk',
  YOU_TUBE = 'youtube',
  YOU_TUBE_MUSIC = 'youtubeMusic',
}

export const isServiceType = (serviceType): serviceType is ServiceTypes =>
  Object.values(ServiceTypes).includes(serviceType);

export enum UserTypes {
  DEFAULT = 'default',
  ORCHARD = 'orchard',
}

export enum PageTypes {
  ACCOUNT = 'account',
  ANALYTICS = 'analytics',
  ARTICLE = 'article',
  ARTIST = 'artist',
  ALBUM = 'album',
  CONVERT = 'convert',
  CREATE = 'create',
  EDIT = 'edit',
  HOME = 'home',
  ITEMS_INDEX = 'itemsIndex',
  SHARE_TARGET = 'shareTarget',
  TRACK = 'track',
}

export interface SongwhipTokenPayload {
  userId: number;
  userType: UserTypes;
  userBrand?: OrchardBrands;
  iat: number;
}
