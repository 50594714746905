const DEFAULT_LANGUAGE = 'en';
const FRAGMENT_ROOT_KEY = 'app';

/**
 * @param {boolean} [isProductionOverride] - in test optionally get production config
 */
const config = (isProductionOverride) => {
  const isProduction =
    isProductionOverride !== undefined
      ? isProductionOverride
      : process.env.SONGWHIP_ENV === 'production';

  return {
    defaultCode: DEFAULT_LANGUAGE,
    defaultLanguage: DEFAULT_LANGUAGE,
    fallbackToDefault: true,
    fragmentFileName: 'i18n.json',
    fragmentKeyByFolder: false,
    fragmentRootKey: FRAGMENT_ROOT_KEY,
    globalScope: false,
    inputPaths: ['./app', './pages'],
    typescript: true,
    outputPath: './locales',

    // when changing locales remember to check cypress/e2e tests (i18n.ts, cmsPage.ts)
    locales: isProduction
      ? // languages enabled in production
        [DEFAULT_LANGUAGE, 'zh-CN', 'zh-TW', 'pt']
      : // languages enabled in staging/dev/test
        [
          DEFAULT_LANGUAGE,
          'es', // spanish
          'fr', // french
          'pt', // portuguese
          'tr', // turkish
          'zh-CN', // chinese
          'zh-TW', // taiwanese
          'ja', // japanese
          'ko', // korean
        ],
  };
};

config.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
config.FRAGMENT_ROOT_KEY = FRAGMENT_ROOT_KEY;

module.exports = config;
