import React, { useEffect } from 'react';
import Head from 'next/head';

import { Features } from '~/app/lib/store/session/types';
import useIsEnabled from '~/app/lib/hooks/useIsEnabled';
import { useTracker } from '~/app/lib/tracker/useTracker';
import { on } from '~/app/lib/utils/events';
import { OneTrustGroups } from '~/app/lib/oneTrust/types';

const mapOneTrustGroup = (group: string) => {
  switch (group) {
    case OneTrustGroups.STRICTLY_NECESSARY:
      return 'strictly_necessary';
    case OneTrustGroups.PERFORMANCE:
      return 'performance';
    case OneTrustGroups.FUNCTIONAL:
      return 'functional';
    case OneTrustGroups.TARGETING:
      return 'targeting';
    case OneTrustGroups.SOCIAL_MEDIA:
      return 'social_media';
    default:
      return group;
  }
};

export const OneTrust = React.memo(() => {
  const isEnabled = useIsEnabled(Features.ONE_TRUST);
  const { trackEvent } = useTracker();

  useEffect(() => {
    return on(
      window,
      'OneTrustGroupsUpdated',
      (event: CustomEvent<string[]>) => {
        trackEvent(
          {
            type: 'gdpr-consent',
            groups: event.detail.map(mapOneTrustGroup),
          },
          {},
          { skipAnalyticsOptOutCheck: true }
        );
      }
    );
  }, []);

  return isEnabled ? (
    <>
      <style jsx global>{`
        /* Reversed OneTrust animation for floating PC (Preference center) button */
        @keyframes otFloatingBtnIntroRight {
          0% {
            opacity: 0;
            right: -75px;
          }
          100% {
            opacity: 1;
            right: 1%;
          }
        }

        /* Re-write our global svg css style */
        #onetrust-consent-sdk svg,
        #ot-sdk-btn-floating svg {
          display: inline;
        }

        #ot-sdk-btn-floating {
          display: none;
        }

        /*
          Set PC button position to right side of the screen (it overlaps side nav on the left side)
          REVIEW: Do we really need to persist PC button?
        */
        #onetrust-consent-sdk #ot-sdk-btn-floating.ot-floating-button {
          right: 10px;
          animation: otFloatingBtnIntroRight 800ms ease 0ms 1 forwards;
        }

        /*
          Hide PC hint tooltip and arrow, cause it is not fit on the screen,
          but we still have native tooltip
        */
        #onetrust-consent-sdk #ot-sdk-btn-floating::before,
        #onetrust-consent-sdk #ot-sdk-btn-floating::after {
          display: none;
        }
      `}</style>
      <Head>
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script="50db3430-ad8d-4545-bbba-6ed6d7a6feba-test"
          async
        />
      </Head>
    </>
  ) : null;
});
