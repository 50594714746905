import { MappedArtist } from '../../mapper/types';

import {
  OrchardBrands,
  SubscriptionStatuses,
  SubscriptionTypes,
  ArtistPayload,
  ReleaseTaskTypes,
  AccountConfig,
} from '../types';

import { User } from '../users';

export interface AccountAnalyticsItemData {
  pageViews?: {
    total: number;

    countries: {
      [country: string]: number;
    };

    referrers: {
      [referrer: string]: number;
    };
  };

  openEvents?: {
    [url: string]: number;
  };

  presaveEvents?: Record<ReleaseTaskTypes, number>;
}

export type AccountAnalytics = {
  timestamp: number;
  date: string;

  artists: {
    [artistId: string]: AccountAnalyticsItemData;
  };

  albums: {
    [albumId: string]: AccountAnalyticsItemData;
  };

  tracks: {
    [trackId: string]: AccountAnalyticsItemData;
  };
}[];

// TODO: use types from @theorchard/songwhip-api
export interface Subscription {
  items: [
    {
      id: string;
      quantity: number;

      price: {
        id: string;
        name: string;
        interval: 'month' | 'year';
        amount: number;
        currency: 'usd';
        artistCredits: 1;
      };
    },
  ];

  status:
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'unpaid';

  createdAt: number;
  currentCycleStartedAt: number;
  currentCycleEndsAt: number;
  canceledAt: number | null;

  latestInvoice: {
    id: string;
    status: 'deleted' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';

    paymentIntent: {
      id: string;
      status: 'succeeded';
    };
  };
}

export interface AccountUser
  extends Omit<
    User,
    'role' | 'isAdmin' | 'isOrchardUser' | 'isOrchardAdmin' | 'featureFlags'
  > {
  isAccountAdmin: boolean;
  joinedAtTimestamp: number;
}

export enum CustomDomainStatuses {
  PENDING = 'pending',
  ACTIVE = 'active',
}

export interface CustomDomain {
  id: number;
  domain: string;
  status: CustomDomainStatuses;
}

// TODO: use types from @theorchard/songwhip-api
export interface AccountPayload {
  id: number;
  name: string;
  subscriptionEndsAt?: string;
  subscriptionStatus: SubscriptionStatuses;
  subscriptionType: SubscriptionTypes;
  artists?: ArtistPayload[];
  users?: {
    id: User['id'];
    name: User['name'];
    email: User['email'];
    image: User['image'];
    joinedAt: string;
    role: 'admin' | 'default';
  }[];
  subscription?: Subscription;
  totalArtistCredits: number | undefined;
  usedArtistCredits: number | undefined;
  orchardBrand?: OrchardBrands | null;
  config: AccountConfig | null;
  customDomains?: CustomDomain[];
}

export interface MappedAccount {
  id: number;
  name: string;
  subscriptionEndsAtTimestamp?: number;
  subscriptionStatus: SubscriptionStatuses;
  subscriptionType: SubscriptionTypes;
  artists?: MappedArtist[];
  users?: AccountUser[];
  subscription?: Subscription;
  totalArtistCredits: number | undefined;
  usedArtistCredits: number | undefined;
  orchardBrand?: OrchardBrands;
  config: AccountConfig | null;
  customDomains?: CustomDomain[];
}
