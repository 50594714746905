import ApiError from '~/app/lib/errors/ApiError';
import { User } from '~/app/lib/songwhipApi/users';
import { UserTypes } from '~/types';
import { StatusTypes } from '../types';
import { Geolocation, OrchardBrands } from '../../songwhipApi/types';

export enum Features {
  GOOGLE_ONE_TAP = 'songwhip_google_one_tap',
  CUSTOM_BRANDS = 'songwhip_custom_brands',
  TERRITORY_OVERRIDES = 'songwhip_territory_overrides',
  EMAIL_AND_OPT_INS_BEFORE_PRESAVE = 'songwhip_email_and_opt_ins_before_presave',
  SHOWS = 'songwhip_shows',
  LOGIN_NEXT = 'login_next',
  ONE_TRUST = 'one_trust',
  AMAZON_MUSIC_PRESAVE = 'songwhip_amazon_music_presave',
  EMBEDDED_VIDEOS = 'songwhip_embedded_videos',
  // TODO: Get rid when features ready
  DEFAULT_CUSTOM_LINK = 'songwhip_default_custom_link',
  UTM_PARAMS = 'songwhip_utm_params',
  DIRECT_SERVICE_LINKS = 'songwhip_direct_service_links',
  TERRITORY_PICKER = 'songwhip_territory_picker',
  SETUP_CUSTOM_DOMAIN = 'songwhip_setup_custom_domain',

  /**
   * Enables advanced page customizations:
   * - Background color
   * - Inline images
   * - Customizable page title
   */
  PAGE_THEME = 'songwhip_page_theme',
}

export type SessionState = {
  scope: string | undefined;
  customDomain: string | undefined;
  hydrated: boolean;
  country: string;
  language: string;
  deviceType: 'small' | 'large';

  userBrand: OrchardBrands | undefined;
  userType: UserTypes | undefined;
  userId: number | undefined;
  userAgent: string;

  /**
   * List of feature flags enabled via `enable` query param
   */
  localFeatureFlags?: Features[];

  /**
   * Used to define an override 'ref' that will fetch cms content from
   * a different 'commit'. We use this to preview unpublished cms content.
   * If a ref isn't passed to songwhip-cms, it internally defaults to the latest ref/content.
   */
  cmsRef?: string;

  user: {
    status?: StatusTypes;
    value?: User;
    error?: ApiError;
  };

  /**
   * The user's lat/long coming from the edgeWorker
   */
  geolocation?: Geolocation;

  /**
   * When a `utm_source` or `utm_medium` query params are present on page-load
   * we store them in the redux store and attach them to all songwhip-events.
   */
  utmSource?: string;
  utmMedium?: string;
};

export enum SessionActionTypes {
  FETCH_USER_START = 'FETCH_USER_START',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR = 'FETCH_USER_ERROR',
  SESSION_INIT_CLIENT = 'SESSION_INIT_CLIENT',
  SESSION_LOGIN_START = 'SESSION_LOGIN_START',
  SESSION_LOGIN_SUCCESS = 'SESSION_LOGIN_SUCCESS',
  SESSION_LOGIN_ERROR = 'SESSION_LOGIN_ERROR',
  SESSION_LOGOUT = 'SESSION_LOGOUT',
  SET_USER_TYPE = 'SET_USER_TYPE',
}

export interface SessionInitClientAction
  extends Pick<
    SessionState,
    | 'userType'
    | 'userBrand'
    | 'userAgent'
    | 'userId'
    | 'cmsRef'
    | 'localFeatureFlags'
    | 'geolocation'
  > {
  type: SessionActionTypes.SESSION_INIT_CLIENT;
}

export interface SessionLoginSuccessAction {
  type: SessionActionTypes.SESSION_LOGIN_SUCCESS;
  token: string;
  user: User;
  userType: UserTypes | undefined;
  userBrand: OrchardBrands | undefined;
}
