import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from '~/app/lib/store/redux';
import { User, UserAccount } from '~/app/lib/songwhipApi/users';
import { OrchardBrands, SubscriptionTypes } from '~/app/lib/songwhipApi/types';
import deferred from '../../utils/deferred';

import {
  selectSessionUserId,
  selectUserIsLoading,
  selectUserError,
  selectUser,
} from '~/app/lib/store/session/selectors';
import { useAppRouter } from '../../router2';
import { getClientCookie } from '../../utils/cookie';
import { ACCOUNT_COOKIE_NAME } from '../../constants';

const initialUserDeferred = deferred<User | undefined>();

const useFetchSessionUser = () => {
  const dispatch = useDispatch();
  const router = useAppRouter();
  const userId = useSelector(selectSessionUserId);
  const userIsLoading = useSelector(selectUserIsLoading);
  const userError = useSelector(selectUserError);
  const user = useSelector(selectUser);
  const userIsLoaded = !!user;
  const isLoggedIn = !!userId;

  const userAccount = pickMainAccount(user?.accounts);
  const subscriptionEndsAt = userAccount?.subscriptionEndsAt;

  // if a subscriptionEndsAt is defined it means that
  // this account has/has-had a subscription.
  // We also consider any Orchard user as having an active subscription.
  const hasSubscription = !!subscriptionEndsAt || !!user?.isOrchardUser;

  // TODO: move into selectUser
  const hasActiveSubscription =
    (!!subscriptionEndsAt && subscriptionEndsAt > Date.now()) ||
    !!user?.isOrchardUser;

  // TODO: move into selectUser
  const subscriptionExpired = subscriptionEndsAt && !hasActiveSubscription;

  useEffect(() => {
    // if there's no userId cookie there's no logged in user
    if (!userId) {
      initialUserDeferred.resolve(undefined);
      return;
    }

    // lazy-load to avoid bundling when user isn't logged in
    import('~/app/lib/store/session/actions/fetchUser').then(
      async ({ fetchUser }) => {
        const user = await dispatch(fetchUser(userId));

        // Resolve the initialUser promise. Although .resolve()
        // can be called multiple times, initialUserDeferred.promise
        // will always resolve to the FIRST value given.
        // https://stackoverflow.com/a/29491617/516629
        initialUserDeferred.resolve(user);

        // If the user is an Orchard Admin they should emulate an Account,
        // if they don't we redirect them to the page where they can pick
        // the Account they want to emulate.
        if (user?.isOrchardAdmin) {
          const accountId = getClientCookie(ACCOUNT_COOKIE_NAME);

          if (!accountId) {
            router.push(`/account/pick?redirectPath=${router.getPathname()}`);
          }
        }
      }
    );
  }, [userId]);

  return useMemo(
    () => ({
      isLoggedIn,
      userIsLoading,
      userIsLoaded,
      userId,
      userError,
      userName: user?.name,
      userEmail: user?.email,
      isOrchardUser: !!user?.isOrchardUser,
      isOrchardAdmin: !!user?.isOrchardAdmin,
      isSmeUser: user?.orchardBrand === OrchardBrands.SME,
      userAccounts: user?.accounts,

      // assume user has only one account for now
      userAccount,
      userAccountId: userAccount?.id,

      hasSubscription,
      hasActiveSubscription,
      subscriptionEndsAt,
      subscriptionExpired,
      user,

      /**
       * A Promise that resolves to the initial User. This is handy if
       * any parts of the app need to await the fetching of the initial
       * logged in User before proceeding.
       *
       * If the user is not logged in this will resolve to undefined.
       */
      initialResolvedUser: initialUserDeferred.promise,
    }),
    [user, isLoggedIn, userIsLoaded, userError]
  );
};

/**
 * In the rare cases where a user has more than one account,
 * we pick the Orchard account as the main one.
 */
export const pickMainAccount = (accounts: UserAccount[] | undefined) => {
  const orchardAccount = accounts?.find(
    ({ subscriptionType }) => subscriptionType === SubscriptionTypes.ORCHARD
  );

  return orchardAccount || accounts?.[0];
};

export default useFetchSessionUser;
