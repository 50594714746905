import { useCallback, useEffect, useMemo, useState } from 'react';

import { OneTrustGroups } from '~/app/lib/oneTrust/types';
import { Features } from '~/app/lib/store/session/types';
import useIsEnabled from '~/app/lib/hooks/useIsEnabled';
import { on } from '~/app/lib/utils/events';

/**
 * A hook that responds to the OneTrust privacy consent preferences,
 * exposing them to components that need to conditionally change
 * logic based on user preferences.
 */
const useOneTrust = () => {
  const [activeGroups, setActiveGroups] = useState<string[]>([]);

  // Currently behind a feature flag as we don't have OneTrust integrated yet.
  // We enable the feature in cypress using the `?enable=one_trust` param.
  // When not enabled all privacy related features are preffed on.
  const isEnabled = useIsEnabled(Features.ONE_TRUST);

  const updateActiveGroups = useCallback(
    (groups: string[] = []) => {
      setActiveGroups(groups);
    },
    [setActiveGroups]
  );

  useEffect(() => {
    const value: string | undefined = (window as any).OnetrustActiveGroups;

    if (value) {
      const groups = value.split(',').filter(Boolean);
      updateActiveGroups(groups);
    }

    return on(
      window,
      'OneTrustGroupsUpdated',
      (event: CustomEvent<string[]>) => {
        updateActiveGroups(event.detail);
      }
    );
  }, [updateActiveGroups]);

  return useMemo(() => {
    const result = {
      analyticsEnabled: isEnabled
        ? activeGroups.includes(OneTrustGroups.PERFORMANCE)
        : true,

      pixelsEnabled: isEnabled
        ? activeGroups.includes(OneTrustGroups.TARGETING)
        : true,
    };

    return result;
  }, [activeGroups]);
};

export default useOneTrust;
