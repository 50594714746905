import { createStore, applyMiddleware, combineReducers, Reducer } from 'redux';
import reduxThunk from 'redux-thunk';
import Debug from 'debug';

import artistsReducer from './artists/reducer';
import sessionReducer from './session/reducer';
import albumsReducer from './albums/reducer';
import tracksReducer from './tracks/reducer';
import pathsReducer from './paths/reducer';
import { AppReduxStore, LazyReducers } from './types';

const IS_DEV = process.env.NODE_ENV !== 'production';

const devtools = IS_DEV
  ? require('redux-devtools-extension').composeWithDevTools
  : (arg) => arg;

const debug = Debug('songwhip/store/create-store');

/**
 * The base reducers are loaded for all pages. Some more page specific (aka. lazy)
 * reducers (eg. accounts) are installed per page using `useAddReducer()`.
 * This reduces the baseline app bundle size.
 */
const baseReducers = {
  artists: artistsReducer,
  albums: albumsReducer,
  tracks: tracksReducer,
  session: sessionReducer,
  paths: pathsReducer,
};

const initialReducer = combineReducers(baseReducers);

const createAppStore = (initialState = {}) => {
  debug('create');

  const store = createStore(
    initialReducer,
    initialState,
    devtools(applyMiddleware(reduxThunk))
  ) as AppReduxStore;

  store.lazyReducers = {};

  return store;
};

/**
 * Dynamically add a reducer to the redux store.
 *
 * Useful for incrementally increasing the store instead of having
 * to bundle all the reducers into the shared _app bundle.
 */
export const addReducer = (
  store: AppReduxStore,
  reducers: Partial<Record<keyof LazyReducers, Reducer>>
) => {
  (Object.keys(reducers) as (keyof LazyReducers)[])
    .filter((key) => !store.lazyReducers[key])
    .forEach((key) => {
      debug('add reducer "%s"', key);
      store.lazyReducers[key] = reducers[key];
    });

  // GOTCHA: this can trigger past actions to run through the reducer
  // again when the redux-devtools is loaded in browser. This can cause
  // parts of the state to be reformed and invalidate reselect caches.
  // https://github.com/reduxjs/redux-devtools/issues/378
  store.replaceReducer(
    combineReducers({
      ...baseReducers,
      ...store.lazyReducers,
    }) as Reducer
  );
};

export default createAppStore;
